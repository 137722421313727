import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Programs from "../components/programs";

const programs = ({ data }) => {
  const {
    allContentfulProgram: { nodes: programs },
  } = data;

  return (
    <Layout>
      <Seo
        title="Accompagnement"
        description="Nos programmes pour t'accompagner"
      />
      <Programs data={programs} />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulProgram(sort: { order: ASC, fields: createdAt }) {
      nodes {
        content {
          content
        }
        featured
        title
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

export default programs;
